import { Outlet } from 'react-router'

import styles from './AppShell.module.css'

function AppShell(props) {

    return (        
        <div className={styles.container}>
            <Outlet />
        </div>
    )
}

export default AppShell