const featureList = [
    'Fachspezifische Module',
    'Aufgabenmanagement',
    'In verschiedenen Sprachen',
    'Datenspeicherung nach DSGVO',
    'Serverstandort in Deutschland (ISO 27001)',
]

function useFeatureList() {

    return featureList
}

export default useFeatureList