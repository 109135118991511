import { useState } from "react"

import styles from './ContentBox.module.css'

function ContentBox(props) {

    const [isCollapsed, setIsCollapsed] = useState(props.collapsed)

    const onToggle = () => setIsCollapsed(!isCollapsed)

    return (
        <div className={props.withStyle ? `${styles.container} ${styles.styled}` : styles.container}>
            <div className={styles.title} onClick={onToggle}><b>{props.title}</b></div>
            {!isCollapsed && (
                <div className={styles.content}>
                    {props.children}
                </div>
            )}
        </div>
    )
}

ContentBox.defaultProps = {
    collapsed: false,
    withStyle: false
}

export default ContentBox