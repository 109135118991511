import styles from './Message.module.css'

function Message(props) {

    const renderStyle = () => {
        const type = props.error
        if (type) {
            return `${styles.container} danger`
        }
        return `${styles.container} warning`
    }
    return (
        <div className={renderStyle()}>
            <div className={styles.icon}>
                <span>!</span>
            </div>
            <div className={styles.content}>
                <span className={styles.text}>{props.text}</span>
            </div>
        </div>
    )
}

export default Message