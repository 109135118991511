import { useFormik } from "formik"
import { useState } from "react"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router"
import * as yup from 'yup'
import Message from "../../components/Message"

import styles from './SignupForm.module.css'
import { registerAccount } from "./signupSlice"
import TermsAndPrivacy from "./TermsAndPrivacy"

const schema = yup.object().shape({
    organisationName: yup.string().required(),
    email: yup.string().email().required(),
    password: yup.string().required(),
})

function SignupForm() {
    const [message, setMessage] = useState(null)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const formik = useFormik({
        initialValues: {
            organisationName: '',
            email: '',
            password: '',
        },
        validationSchema: schema,
        onSubmit: async values => {

            if (typeof values.username === 'undefined' || values.username.length === 0) {
                let baseUsername = values.email.split('@')
                values.username = baseUsername[0]

                // TODO
                values.title = 'NONE'
                values.firstName = 'Building'
                values.lastName = 'Scout'
            }

            const result = await dispatch(registerAccount(values))

            if (result.success) {
                navigate('/start')
            } else {
            }
        }
    })

    const renderMessage = (message, errors) => {
        if (Object.keys(errors).length > 0) {
            return <Message text="Eingabefelder überprüfen" error />
        } else if (message) {
            return <Message text={message} error />
        }

        return null
    }

    return (
        <div className={styles.container}>
            <div className={styles.banner}>
                <span><b>Nachhaltig digital!</b></span>
                <p>Papierlose Rundgänge und Auditvorbereitung zukünftig mit BuildingScout durchführen?</p>
                <span>Ich möchte direkt loslegen!</span>
            </div>
            <form className={styles.form} onSubmit={formik.handleSubmit}>
                <div className={styles.content}>
                    {(message || (formik.dirty && formik.errors)) && renderMessage(message, formik.errors)}
                    <label htmlFor="organisationName">Organisation</label>
                    <input id="organisationName" type="text" name="organisationName" value={formik.values.organisationName} onChange={formik.handleChange} autoComplete="off" />
                    <label htmlFor="email">Email</label>
                    <input id="email" type="email" name="email" value={formik.values.email} onChange={formik.handleChange} autoComplete="off" />
                    <label htmlFor="password">Passwort</label>
                    <input id="password" type="password" name="password" value={formik.values.password} onChange={formik.handleChange} autoComplete="new-password" />
                    <TermsAndPrivacy />
                    <button className={styles.button} type="submit">
                        <div className={styles['button-content']}>
                            <span>{formik.isSubmitting ? '...' : 'Kostenloses Konto erstellen'}</span>
                        </div>
                    </button>
                </div>
            </form>
        </div>
    )
}

export default SignupForm