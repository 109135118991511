import styles from './Guide.module.css'

function Guide(props) {

    const isOdd = (value) => {
        return value % 2 !== 0
    }

    return (
        <div className={styles.container}>
            {props.data.map((item, index) => (
                <div key={Math.random()} className={isOdd(index) ? `${styles.step} ${styles.odd}` : styles.step}>
                    <div className={styles.image}>{item.image}</div>
                    <div className={styles.text}>{item.text}</div>
                </div>
            ))}
        </div>
    )
}

export default Guide