import React from 'react';
import { useRoutes } from 'react-router';

import AppShell from './components/AppShell';
import Welcome from './views/Welcome';
import Terms from './views/Terms';
import Privacy from './views/Privacy';
import NextSteps from './views/NextSteps';

import './App.css';

function App() {
  let element = useRoutes([
    {
      path: '/', 
      element: <AppShell />,
      children: [
        { path: '/', element: <Welcome /> },
        { path: '/start', element: <NextSteps /> },
      ]
    },
    { path: '/terms', element: <Terms /> },
    { path: '/privacy', element: <Privacy /> },
  ])

  return element
}

export default App;
