import styles from './Button.module.css'
function Button(props) {

    return (
        <button className={styles.base} type="button" onClick={props.onclick} disabled={props.disabled}>
            {props.children}
        </button>
    )
}

export default Button