import Button from './Button'
import Guide from './Guide'
import styles from './Page.module.css'

import imgCollection from '../assets/collection.png'

function Page(props) {

    const onClose = () => props.onclose()
    return (
        <div id="group_page" className={styles.container}>
            <h3 className={styles.category}>
                {props.data.name}
            </h3>
            {props.data.tags.length > 0 && (
                <div className={styles.tags}>
                    {props.data.tags.map(tag => <span key={Math.random()} className={styles.tag}>{tag}</span>)}
                </div>
            )}
            <div className={styles.content}>
                {props.data.content.map(item => (
                    <div key={Math.random()} className={styles.section}>
                        <div>
                            <span className={styles.title}>{item.title}</span>
                        </div>
                        <div>{item.body}</div>
                    </div>
                ))}
            </div>
            <div className={styles.banner}>
                <img src={imgCollection} alt="collection" />
            </div>
            <Guide data={props.data.guide} />
            <div className={styles.back}>
                {/* <a href="#page_group">Anderen Nutzer ansehen</a> */}
                <Button onclick={onClose}>
                    <div className={styles.button}>
                        <span>Ich bin doch ein anderer Nutzer</span>
                    </div>
                </Button>
            </div>
        </div>
    )
}

export default Page