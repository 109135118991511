import { useNavigate } from "react-router"
import ScrollContent from "../components/ScrollContent"

function Terms() {    
    const navigate = useNavigate()
    const onClick = () => navigate('/')

    return (
        <ScrollContent onclick={onClick}>
            <h1>General Terms of Service of axcorn GmbH for a corporate Free-Account for using the Cloud-Software BuildingScout</h1>

            <h2>Definitions</h2>
            <span>1. "Agreement" means collectively all terms, conditions, no-tices and all other operating rules, policies (including the Privacy Policy) and procedures contained or referenced in this document ("Terms and Conditions" or "Terms of Service” or "Terms of Use" or "Terms").</span>
            <span>2. The term "Service" refers to the applications, software, products and services provided by axcorn GmbH.</span>
            <span>3. the "website" refers to the website at www.BuildingScout.eu as well as all content, services and products that are provid-ed by the provider on or via the website.</span>
            <span>4. An "account" or "user account" represents the legal rela-tionship between the contractual partner and axcorn GmbH.</span>
            <span>5. "Provider" or “axcorn” refers to axcorn GmbH as well as its contractors and employees.</span>
            <span>6. The “contractual partner” refers to the company or organi-zation that sets up a business user account ("Free Account") and thereby enters into the contractual agreement with ax-corn GmbH.</span>
            <span>7. "User" or "User" refers to the person who visits or uses the Website or the Service, accesses or uses any part of the ac-count through a BuildingScout user account, or uses the ad-ministration of the account to perform its functions. A "user account" gives an individual user permission to log in to and use the Service and serves as a user identity for the Building-Scout software.</span>
            <span>8. "Content" means any content presented or displayed on the Site, including but not limited to text, data, articles, imag-es, photographs, graphics, software, applications, designs, features and other materials available on the Site or otherwise made available through the Services. "Content" includes Ser-vices. "User Generated Content" means Content written or otherwise created or uploaded by Users. "Content" means Content created or owned by the Account User.</span>
            <span>9. "Content" and "Data Content" shall mean any information added to the System by or for the User, such as data points, events or objects (e.g. floor plans, pictures, etc.).</span>
            <h2>1. Subject of the contract</h2>
            <p>1.1 These General Terms and Conditions apply to all free services and content offered by the provider via a free Build-ingScout user account for companies ("Free Account"), in particular on the BuildingScout website under the URL http://www.BuildingScout.eu as well as the associated sub-pages (Second- / Third-Level-Domains such as go.BuildingScout.de and digital.BuildingScout.eu).</p>
            <p>1.2 The contractual partner shall receive access to the Build-ingScout software (the "Software") as Software-as-a-Service (SaaS) or cloud offer via its own user account. The software is provided for the use and storage of data via the Internet. The software is provided as it is at the time of transfer ("as is"). The service is based on the service description of the Free Account.</p>
            <p>1.3 The account user is enabled to save and process the software stored and running on the servers of the provider or a service provider commissioned by the provider via an in-ternet connection.</p>
            <p>1.4 The services requiring registration, including their addi-tional services, are intended exclusively for companies and for commercial or business use. They are to be used for own purposes, as they serve companies for their internal business transactions. These contractual terms and conditions apply to all contractual partners who are not consumers within the meaning of § 13 BGB (German Civil Code) or other relevant legal provisions and are concluded via the website.</p>
            <p>1.5 Separate agreements apply to Premium Accounts.</p>
            <h2>2. Type and scope of the service, rights of use</h2>
            <p>2.1 The provider provides the contractual partner with the software for use on his website online via a user account.</p>
            <p>2.2 The provider grants the contractual partner the non-exclusive, non-transferable right to load the user interface of the software for display on the screen into the working memory of the terminal devices used for this purpose and to make copies of the user interface that are created in the pro-cess, as well as to use the software for the contractual pur-poses in accordance with the product description. In addi-tion to the right to use the software, the contractual partner shall be granted the right to store content on the server oper-ated by the Provider or its contractual partners.</p>
            <p>2.3 Access to the software and its contents is granted to the contractual partner via his user account using an Internet connection.</p>
            <p>2.4 The contractual partner receives a license with a non-transferable user account for one user.</p>
            <p>2.5 The right to use the software is limited exclusively to use for own purposes in accordance with the terms of this agreement. The contractual partner is prohibited from subli-censing, licensing, selling, leasing, renting, outsourcing or making the Service available to third parties, unless otherwise agreed in writing.</p>
            <p>2.6 Within the scope of this agreement, if the software is ac-cessed via a user account, the contractual partner acknowl-edges the aforementioned services in sections 1 to 3.</p>
            <p>2.7 Since the software runs exclusively on the servers of the Provider or on servers of service providers commissioned by the Provider, the contractual partner does not require any copyright rights to use the software, and the Provider does not grant any such rights.</p>
            <p>2.8 For the purposes of this Agreement, when accessing the free (toll-free) version of the Service via a Free Account, the Contracting Party acknowledges that the Provider is not obliged to provide certain support services and that the pro-vision of the Service may be discontinued at any time without prior notice. This provision shall take precedence over any other provisions of this Agreement that deviate from it.</p>
            <p>2.9 The provided software is legally protected. The copyright and all other ancillary copyrights to the software and other objects that the Provider provides to the contractual partner are the exclusive property of the Provider. If third parties are entitled to these rights, the provider has the corresponding rights of exploitation. Copyright notices and other features serving to identify the program may not be passed on, re-moved or changed under any circumstances. Re-translation of the provided program code into other code forms (de-compilation) and other types of reverse engineering of the various production stages of the software are not permitted. Translation, processing, arrangement and other reworking of the software are not permitted.</p>
            <h2>3. Conclusion of contract and closing of account</h2>
            <p>3.1 The contract is concluded upon acceptance of the user application via the user account registration (registration).</p>
            <p>3.2 The contractual partner assures that the information about his person and other contract-relevant details and circum-stances provided by him in the context of the contract offer or the conclusion of the contract are complete and correct. The contractual partner undertakes to notify us immediately of any changes to the data. In case of violation, the Provider is enti-tled to block the services immediately.</p>
            <p>3.3 It is the responsibility of the contractual partner to duly terminate his account with the provider. The contractual part-ner may terminate his account at any time by sending an e-mail to the provider at info@buildingscout.eu. The Provider cannot terminate accounts upon request by telephone.</p>
            <p>3.4 The Provider stores and uses the data of the contractual partner as required in order to fulfil its legal obligations, settle disputes and enforce its agreements. However, unless there are legal requirements, the complete user account and its content will be deleted within 90 days after termination or termination (some information may remain in encrypted backups). This information cannot be recovered once your account has been terminated.</p>
            <p>3.5 The Provider has the right to block access to all or part of the Website at any time, with or without cause, with or without notice, with immediate effect. The Provider reserves the right to refuse service at any time for any reason.</p>
            <p>3.6 All provisions of this Agreement which by their nature should survive termination shall survive termination, includ-ing but not limited to: Ownership provisions, disclaimers of warranties, indemnity and limitations of liability.</p>
            <h2>4. User account (Account)</h2>
            <p>4.1 The user must provide a valid e-mail address of the or-ganization to complete the registration process.</p>
            <p>4.2 With the user account the user has the final administrative control over his account and all content contained therein.</p>
            <p>4.3 The user, in the sense of these terms and conditions, is considered by the provider to be responsible for the actions carried out by the organization.</p>
            <p>4.4 A person or legal entity may not hold more than one free account.</p>
            <p>4.5 A user account or login may only be used by one person - i.e. a single login may not be shared by several people.</p>
            <p>4.5 Users must be at least 18 years old. The offer is not di-rected at persons under 18 years of age, and the Provider does not allow users under 18 to use the Service. If the Pro-vider learns that a User is under 18 years of age, the Provider will immediately terminate that User's account. If you are lo-cated in a country outside the European Union, the minimum age in your country may be older. In this case you are re-sponsible for compliance with the laws of your country.</p>
            <p>4.6 The user must be a human to create an account. Accounts registered by "bots" or other automated methods are not al-lowed.</p>
            <p>4.7 The User may not use the Services in violation of the ex-port control or sanction laws of the European Union or Ger-many or any other applicable jurisdiction.</p>
            <h2>5. Data processing rights and user-generated content</h2>
            <p>5.1 The user can create or upload (store) user-generated con-tent while using the service. He is solely responsible for the content and for damages resulting from content created by users. The provider carries out regular backups. Neverthe-less, it is possible that user-generated content may be lost. The provider cannot be held liable for damages resulting from the loss of data.</p>
            <p>5.2 The provider is not responsible for the display or abuse of user-generated content.</p>
            <p>5.3 The Provider does not pre-screen User Generated Con-tent but has the right (but not the obligation) to refuse or re-move User Generated Content that, in its sole discretion, violates any law or regulation.</p>
            <p>5.4 The contracting party retains ownership of and responsi-bility for its content. If the user creates content that he has not created himself or whose rights he does not own, the con-tractual partner agrees that he is responsible for the content he has posted, that he only uses or creates content that he is entitled to publish and that he fully complies with all third party license terms with regard to the content he has posted.</p>
            <p>5.5 As the contractual partner retains ownership of its content and responsibility for its content, it grants the provider cer-tain authorisations, which are listed in sections 6.6 - 6.9, to ensure the execution of the provider's services. The contrac-tual partner shall not receive payment for the rights granted in sections 5.6 - 5.9. The authorizations granted to the Pro-vider shall be terminated if the Provider removes its content from the Provider's servers.</p>
            <p>5.6 The contractual partner grants the provider the right to reproduce the data to be stored by the contractual partner and its users for the purposes of implementing the contract, insofar as this is necessary to provide the services owed under this contract. This serves exclusively to provide and secure the data for the contractual partner within the scope of possible changes at the Provider (storage on additional servers due to changes in the infrastructure, database reloca-tion for extended software application, backups etc.). The Provider is also entitled to store the data in a failure system or separate failure computer centre. In order to eliminate faults, the Provider is also entitled to make changes to the structure of the data or the data format.</p>
            <p>5.7 The provider requires the right to host and share content. The contractual partner grants the Provider and its legal suc-cessors the right to store, analyse and display the user-generated content and, if necessary, to make copies in order to render the website and provide the service. This includes the right to copy things in his database and to make backups; to show content to the contractual partner; to analyze it in a search index or elsewhere on the servers of the Provider; and to play it if your content is music or video.</p>
            <p>5.8 The Provider is not granted the right to sell or otherwise distribute or use the Content outside the provision of its Ser-vice.</p>
            <p>5.9 Provider's employees can only access the content of the contractual partner's accounts if access is necessary for se-curity reasons or to maintain the confidentiality, integrity, availability and reliability of the systems and services.</p>
            <p>5.10 If the provider has reason to believe that the content violates the law or these conditions, he has the right to ac-cess, check and remove it. In addition, he may be required by law to disclose the Content.</p>
            <p>5.11 The Provider considers the Content to be confidential to the Party. He shall protect the Content against unauthorized use, access or disclosure in the same way as the Provider would use it to protect his own confidential information of a similar nature and in no case with a reasonable degree of care.</p>
            <p>5.12 The Provider shall comply with the statutory data protec-tion provisions and conclude a separate data protection agreement.</p>
            <h2>6. Obligations of the contracting party to cooperate</h2>
            <p>6.1 The contracting party will support the provider in provid-ing the contractual services to an appropriate extent.</p>
            <p>6.2 The contractual partner shall name a responsible contact person. The communication between the provider and the contractual partner shall take place via this contact person. The contact person shall immediately bring about all deci-sions related to the execution of the contract.</p>
            <p>6.3 The contractual partner shall ensure that the data provid-ed, which are required for the system installation, are up-to-date before the installation begins.</p>
            <p>6.4 The contractual partner shall keep the access data made available to it secret and ensure that any employees to whom access data are made available also do so. The service of the Provider may not be made available to third parties unless this has been expressly agreed by the parties.</p>
            <p>6.5 In order to use the software, the system requirements re-sulting from the product description must be fulfilled at the contracting party. The contractual partner is responsible for this himself.</p>
            <p>6.6 The contractual partner shall be responsible for the tech-nical setup and ad registration of the account.</p>
            <p>6.7 The contractual partner is obliged to test the program thoroughly for usability in the concrete situation before start-ing productive use.</p>
            <p>6.8 It is the responsibility of the contractual partner to ensure the functionality of the program's working environment.</p>
            <p>6.9 For optimal use of the offers and functions, the contractu-al partner will use the browser types Google Chrome or Mozil-la Firefox in their respective current version. In addi-tion, the settings in the browser used must permit the use of cookies. If these technical requirements are not met by the contractual partner, the usability of the services may be re-stricted under certain circumstances. The provider is not responsible for these restrictions.</p>
            <p>6.10 The connection to the Internet with sufficient bandwidth and latency is the responsibility of the contractual partner.</p>
            <p>6.11 The contractual partner is responsible for taking IT secu-rity measures in accordance with the current state of technol-ogy. These include, but are not limited to, the installation and regular updating of a common anti-virus software on the computers or other mobile end devices, ensuring the alloca-tion and regular updating of secure passwords in accord-ance with recognised security standards.</p>
            <p>6.12 In addition, the contractual partner must ensure the secu-rity of the Internet connection used.</p>
            <p>6.13 The contractual partner shall ensure proper data security and provision for data and components (such as hardware, software).</p>
            <p>6.14 The contractual partner hereby undertakes to use the software only in accordance with the contract and within the scope of the applicable statutory provisions and not to in-fringe the rights of third parties when using it. The contractu-al partner shall inform immediately, if possible in writing, about the misuse or suspected misuse of the contractually agreed service; a risk or suspicion of a risk to compliance with data protection or data security which arises in the course of providing the contractually agreed service; a risk or suspicion of a risk to the service provided, e.g. through loss of access data or hacker attack.</p>
            <h2>7. Limitation of liability and compensation</h2>
            <p>7.1 The provider is always liable to the contracting party
<br />
a) for the damage caused by him and his legal representa-tives or vicarious agents intentionally or through gross neg-ligence
b) according to the product liability law and
c) for damages resulting from injury to life, body or health for which the provider, his legal representatives or vicarious agents are responsible.
</p>
            <p>7.2 The provider is not liable for slight negligence, except in cases of breach of an essential contractual obligation, the fulfillment of which is essential for the proper execution of the contract or the breach of which endangers the achievement of the purpose of the contract and on the compliance of which the contractual partner may regularly rely.</p>
            <p>7.3 This liability is limited in the case of damage to property to the foreseeable damage typical of the contract. This also applies to lost profits and savings. Liability for other remote consequential damages is excluded.</p>
            <p>7.4 The limitation of liability shall also apply to claims against executives, employees, other vicarious agents or subcontrac-tors of the Provider.</p>
            <p>7.5 Claims of the contractual partner for the removal of de-fects by the provider do not exist for software made available free of charge.</p>
            <h2>8. Defects of title</h2>
            <p>8.1 The provider is only liable for violations of third party rights by the service if the service is used unchanged accord-ing to the contract and especially in the contractually agreed upon, otherwise in the intended environment.</p>
            <p>8.2 The provider is liable for violations of third party rights only within the European Union and the European Economic and Currency Area as well as at the place of contractual use of the service. Section 8.8 Sat 1 applies accordingly.</p>
            <p>8.3 If a third party claims against the contractual partner that a service of the provider violates his rights, the contractual partner shall inform the provider immediately. The Provider and, if applicable, its suppliers are entitled, but not obliged, to ward off the asserted claims at their own expense, as far as permissible.</p>
            <p>8.4 The contractual partner is not entitled to acknowledge third-party claims before he has given the provider a reason-able opportunity to defend the rights of third parties in an-other way.</p>
            <p>8.5 If the rights of third parties are infringed by a service pro-vided by the provider, the provider shall, at its own discretion and expense
<br />
a) provide the contracting party with the right to use the ser-vice or
b) render the service free of infringement or
c) take back the service with reimbursement of the remunera-tion paid for it by the contractual partner (less an appropriate compensation for use), if the supplier cannot achieve any other remedy with reasonable effort.
</p>
            <p>8.6 The interests of the contractual partner shall be taken into account appropriately.</p>
            <h2>9. Data of the contracting party and release from claims of third parties</h2>
            <p>9.1 As a technical service provider, the provider stores con-tent and data for the contractual partner, which the contractu-al partner enters and stores and makes available for retrieval when using the software. The contractual partner undertakes towards the provider not to post any criminal or otherwise absolutely or in relation to individual third parties illegal con-tent and data and not to use any programs containing virus-es or other malicious software in connection with the soft-ware. The contractual partner shall remain the responsible authority with regard to personal data and must therefore always check whether the processing of such data on the use of the software is subject to the relevant statutory provisions.</p>
            <p>9.2 The contractual partner shall be solely responsible for all content used and data processed as well as for any legal positions that may be required for this. The provider does not take note of contents of the contractual partner and does not check the contents used by the contractual partner with the software.</p>
            <p>9.3 In this context, the contractual partner undertakes to in-demnify the provider from any liability and any costs, includ-ing possible and actual costs of legal proceedings, if claims are made against the provider by third parties, including em-ployees of the contractual partner personally, as a result of alleged actions or omissions of the contractual partner. The Provider shall inform the Contractual Partner about the claim and, as far as legally possible, give him the opportunity to defend the asserted claim. At the same time, the Contractual Partner shall immediately provide the Provider with all infor-mation available to him on the facts of the case which are the subject of the claim.</p>
            <p>9.4 Any further claims for damages of the Provider shall re-main unaffected.</p>
            <h2>10. Confidentiality</h2>
            <p>10.1 Provider and contracting party are obliged to maintain secrecy about business secrets as well as about other infor-mation designated as confidential (e.g. in documents, rec-ords, data files), which become known in connection with the execution of the contract, and not to use them beyond the purpose of the contract, nor to disclose, record or otherwise exploit them, unless the other party has expressly agreed in writing to the disclosure or use or the information must be disclosed due to law, court decision or an administrative decision.</p>
            <p>10.2 The respective receiving party (provider or contracting party) is obliged to take appropriate secrecy measures for business secrets and for information designated as confiden-tial. The contractual partner is not entitled to obtain business secrets of the other contractual partner by observing, investi-gating, dismantling or testing the object of the contract. The same applies to any other information or objects obtained during the execution of the contract.</p>
            <p>10.3 Business secrets and other information designated as confidential may only be passed on to persons who are not involved in the conclusion, implementation or execution of the contract with the written consent of the other contracting party.</p>
            <p>10.4 The information shall not be considered confidential information within the meaning of this clause 11 if it</p>
(a) were already known to the other party before the infor-mation was disclosed, without any obligation of confidentiali-ty
(b) are generally known or become known without breach of the assumed confidentiality obligation,
(c) disclosed to the other Party by a third party without breach of any confidentiality obligation.
            <p>10.5 Unless otherwise agreed, the obligation to maintain se-crecy for other information designated as confidential shall end five years after the respective information becomes known; in the case of continuing obligations, however, not before their termination. Business secrets are to be kept se-cret indefinitely.</p>
            <p>10.6 The contractual partner shall also impose these obliga-tions on its employees and any third parties it may employ.</p>
            <p>10.7 Provider and contracting party are aware that electronic and unencrypted communication (e.g. by e-mail) is subject to security risks. In this type of communication, they will there-fore not assert any claims based on the absence of encryp-tion, unless encryption has been agreed upon beforehand.</p>
            <h2>11. Data protection</h2>
The contractual partner will conclude the necessary agree-ments with the provider for the handling of personal data in accordance with data protection law.
            <h2>12. Transfer of rights and obligations</h2>
The assignment of the rights and obligations from this con-tract is only permitted with the prior written consent of the Provider. The Provider is entitled to entrust third parties with the fulfilment of the obligations arising from this contract.
            <h2>13. Miscellaneous</h2>
            <p>13.1 This agreement and its amendments as well as all con-tract-relevant declarations, notification and documentation obligations must be made in writing, unless another form is agreed or legally required.</p>
            <p>13.2 German law applies. The application of the UN Sales Convention is excluded.</p>
            <p>13.3 The contractual partner may not use the services of the provider in violation of the export control or sanction laws of the European Union or Germany or any other applicable ju-risdiction.  The contractual partner shall be responsible for observing the import/export regulations applicable to the deliveries and services. In the case of cross-border deliveries or services, the contractual partner shall bear any customs duties, fees and other charges incurred. The contractual partner shall handle legal or official procedures in connec-tion with the cross-border delivery or service on its own re-sponsibility, unless otherwise expressly agreed.</p>
            <p>13.4 The Provider provides his services on the basis of these GTC. GTC of the contracting party are not applicable, even if the provider has not expressly objected to them. Acceptance of the service by the contracting party shall be deemed to be an acknowledgement of these terms and conditions of the provider, waiving any general terms and conditions of the contracting party. Other terms and conditions are only bind-ing if the Provider has acknowledged them in writing; in this case, his terms and conditions of business apply in addition.</p>
            <p>13.5 The provider has the right to change or amend his terms and conditions at any time. The changes and additions to the terms and conditions of business shall be notified to the contractual partner by e-mail to the e-mail address provided by him at least four weeks before the planned coming into force. The agreement of the contractual partner to the amendment of the terms and conditions of business shall be deemed to have been given if the contractual partner does not object to the amendment in text form (e.g. letter, e-mail) within a period of two weeks, beginning on the day following the announcement of the amendment. The Provider under-takes to make separate reference in the amendment notifica-tion to the possibility of objection, the deadline for objection, the text form requirement and the significance or the conse-quences of not objecting.</p>
            <p>13.6 The provider reserves the right to change the software or offer different functionalities.</p>
            <p>13.7 The place of jurisdiction in relation to a merchant, a legal person under public law or a special fund under public law is the registered office of the Provider. He may also sue the con-tractual partner at his registered office.</p>
            <p>13.8 If individual provisions of the General Terms and Condi-tions of Business have not become part of the contract in whole or in part or are ineffective, the remainder of the con-tract shall remain effective. If the provisions have not become part of the contract or are ineffective, the content of the con-tract shall be governed by the statutory provisions.</p>

        </ScrollContent>
    )
}

export default Terms