import Message from '../components/Message'

import styles from './NextSteps.module.css'

import qr2 from '../assets/qr.png'
import qr from '../assets/qr.svg'
import image from '../assets/mobile.png'
import Button from '../components/Button'

function NextSteps() {

    const onClick = () => { }

    return (
        <div className={styles.container}>
            <Message text="Bitte bestätigen Sie Ihre Email. Hinweis: Spam-Ordner prüfen." />
            <div className={styles.headline}>
                <p>
                    Legen Sie jetzt direkt los!
                </p>
            </div>
            <div className={styles.select}>
                <div className={styles.imagebox}>
                    <img className={styles.image} src={image} alt="mobile" />
                    <Button onclick={onClick}>
                        <div className={styles.button}>
                            BuildingScout Go
                        </div>
                    </Button>
                </div>
                <div className={styles.spacer}>oder</div>
                <div className={styles.qrbox}>
                    <img className={styles.qrcode} src={qr} alt="mobile" />
                    <span className={styles.qrfb}>[ <a href="https://go.buildingscout.de">https://go.buildingscout.de</a> ]</span>
                </div>
            </div>
        </div>
    )
}

export default NextSteps