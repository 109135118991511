import a from '../assets/a.png'
import b from '../assets/b.png'
import c from '../assets/c.png'
import d from '../assets/d.png'

function useGroupImages() {

    return {
        "GROUP_A": a,
        "GROUP_B": b,
        "GROUP_C": c,
        "GROUP_D": d
    }
}

export default useGroupImages