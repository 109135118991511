import { useState } from 'react'
import useFeatureList from '../hooks/useFeatureList'
import styles from './FeatureList.module.css'

function FeatureList(props) {
    const [isOpen, setIsOpen] = useState(false)
    const features = useFeatureList()

    const onToggle = () => setIsOpen(!isOpen)

    const renderFeatures = () => {

        if (isOpen) {
            return features.map(item => (
                <li><span>{item}</span></li>
            ))
        } else {
            return features.slice(0, 5).map(item => (
                <li><span>{item}</span></li>
            ))
        }
    }

    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <ul>
                    {renderFeatures()}

                </ul>
                <button className={styles.button} type="button" onClick={onToggle}>
                    <div className={styles['button-content']}>
                        <span>{isOpen ? 'weniger' : 'mehr'}</span>
                    </div>
                </button>
            </div>
        </div>
    )
}

export default FeatureList