import Brand from "../components/Brand"
import ContentBox from "../components/ContentBox"
import SignupForm from "../features/signup/SignupForm"
import FeatureList from "./FeatureList"
import Groups from "./Groups"

function Welcome() {

    return (
        <>
            <Brand />
            <ContentBox title="Ihre Sustainability Toolbox">
                <div>
                    <p>
                        So führen Fachkräfte Ihre Betriebsrundänge von Anfang bis Ende digital durch.
                        Schnell und einfach mit Schritt für Schritt Begleitung und Empfehlungen.
                    </p>
                </div>
            </ContentBox>
            <ContentBox title="Ich bin ..." withStyle>
                <Groups />
            </ContentBox>
            <ContentBox title="">
                <FeatureList />
            </ContentBox>
            <SignupForm />
        </>
    )
}

export default Welcome