import { useEffect, useState } from "react"
import Page from '../components/Page'
import Pagination from "../components/Pagination"
import usePageData from "../hooks/usePageData"

import styles from './Groups.module.css'

function Groups() {
    const [page, setPage] = useState(null)
    const pages = usePageData()

    const onClose = () => {
        setPage(null)
    }

    useEffect(() => {
        // if (pages.length > 0) setPage(pages[0].id)
    }, [])

    return (
        <div className={styles.container}>
            <Pagination data={pages} onselect={setPage} />
            {page && pages.length > 0 && <Page key={Math.random()} data={pages.find(item => item.id === page)} onclose={onClose}/>}
        </div>
    )
}

export default Groups