import axios from 'axios'

const AUTH_HOST = 'https://nebula.buildingscout.de'
const API_HOST = 'https://api.buildingscout.de'
// const AUTH_HOST = 'http://localhost:3060/auth'
// const API_HOST = 'http://localhost:3060/api'
const AUTH_URI = AUTH_HOST
const API_URI = API_HOST + '/v1'

const config = (requiredAuth = true, contentType = null) => {
    const config = {
        headers: {
            'Content-Type': contentType || 'application/json'
        }
    }

    return config
}

export const welcome = {
    register: data => axios.post(`${AUTH_URI}/signup`, data, config(false)).then(response => response.data)
}

export default axios