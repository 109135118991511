import Button from './Button'
import styles from './ScrollContent.module.css'

function ScrollContent(props) {

    const onClick = () => props.onclick()

    return (
        <div className={styles.container}>
            <div className={styles.banner}>
                <Button onclick={onClick}>
                    <div className={styles.button}>back</div>
                </Button>
            </div>
            <div className={styles.content}>
                {props.children}
            </div>
        </div>
    )
}

export default ScrollContent