import styles from './Brand.module.css'

import image from '../assets/sample.png'

function Brand() {

    return (
        <div className={styles.container}>
            <img src={image} />            
            {/* <div className={styles.logo}>IMG</div> */}
            {/* <div>Title</div> */}
        </div>
    )
}

export default Brand