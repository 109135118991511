import { Link } from 'react-router-dom'
import styles from './TermsAndPrivacy.module.css'

function TermsAndPrivacy() {

    return (
        <div className={styles.container}>
            <p>
                Mit dem Klick auf "Kostenloses Konto erstellen" akzeptieren Sie unsere <Link to="/terms">AGBs</Link> und <Link to="/privacy">Datenschutzbestimmungen</Link>. Gelegentlich senden wir Ihnen Konto relevante Emails.
            </p>
        </div>
    )
}

export default TermsAndPrivacy