import { useNavigate } from "react-router"
import ScrollContent from "../components/ScrollContent"

function Privacy() {
    const navigate = useNavigate()
    const onClick = () => navigate('/')

    return (
        <ScrollContent onclick={onClick}>
            <h1>Data Privacy Policy of axcorn GmbH for corporate user accounts for using the enterprise software BuildingScout</h1>

            <h2>Definitions</h2>
            <span>1. The privacy policy applies to all software, accounts and other services provided by us. The privacy statement is an addition to the user or license agreement. If the agreements provide different or additional terms, those terms apply.</span>
            <span>2. The “Service” refers to the software, accounts and services provided by us.</span>
            <span>3. The “Website” refers to website located at buildingscout.eu, and all content, services, and products provided by us at or through the Website. It also refers to our subdomains of buildingscout.eu, such as digital.buildingscout.de and go.buildingscout.de. </span>
            <span>4. “The User,” “You,” and “Your” refer to the individual person, company, or organization that has visited or is using the Website or Service; that accesses or uses any part of the Account; or that directs the use of the Account in the performance of its functions. </span>
            <span>5. “We,” and “Us” refer to axcorn GmbH, as well as our contractors and employees.</span>
            <span>6. “Content” refers to content featured or displayed through the Website, including without limitation text, data, articles, images, photographs, graphics, software, applications, designs, features, and other materials that are available on the Website or otherwise available through the Service. "Content" also includes Services. “User-Generated Content” is Content, written or otherwise, created or uploaded by our Users. "Your Content" is Content that you create or own.</span>
            <span>7. An "Account" represents your legal relationship with us. A “User Account” represents an individual User’s authorization to log in to and use the Service and serves as a User’s identity on BuildingScout. “Organizations” use one or more Users accounts where multiple Users can collaborate. </span>
            <span>8. Content and data content refers to all information that is added to the system by or for the user such as a for example Data Points, Measures or Objects (e.g. floor plans, pictures etc.)</span>
            <h2>Categories of personal information</h2>
            <p>"User Personal Information" is any personal information about one of our users which could, alone or together with other information, personally identify them. Information such as a user name and password, an email address, a real name, and a photograph are examples of “User Personal Infor-mation.” User Personal Information includes Personal Data as defined in the General Data Protection Regulation of the Eu-ropean Union.
            "Technical Information" may include information we collect from website browsers, such as web server logs, or other log information, such as User session or activity logs. Technical Information may be connected to User Personal Information such as a username or an email address, or to other poten-tially personally-identifying information like Internet Protocol (IP) addresses.
            User Personal Information does not include aggregated, non-personally identifying information. We may use aggre-gated, non-personally identifying information to operate, analyze, improve, and optimize our website and service.
</p>
            <h2>Information from users with accounts</h2>
            <p>If you create an account, we require some basic information at the time of account creation. You will create your own user name and password, and we will ask you for a valid email address. You also have the option to give us more infor-mation if you want to, and this may include "User Personal Information."</p>
            <h2>Information from website browsers</h2>
            <p>If you're just browsing the website, we collect the same basic information that most websites collect. We use common in-ternet technologies, such as cookies and web server logs, to collect Technical Information. This is information we collect from everybody, whether they have an account or not.
            The information we collect about all visitors to our website includes the visitor’s browser type, language preference, re-ferring site, additional websites requested, and the date and time of each visitor request. We also collect potentially per-sonally-identifying information like Internet Protocol (IP) ad-dresses.
</p>
            <h2>Information we collect from third parties</h2>
            <p>From time to time, we receive personal information about in-dividuals from third parties. This may happen if you sign up for a webinar or to receive information about us from one of our partners.</p>
            <h2>Why we collect this information</h2>
            <p>We need your User Personal Information to create your ac-count, and to provide the services you request, including to provide the service or to respond to support requests.
            We use your User Personal Information, specifically your user name, to identify you.
            We will use your email address to communicate with you, if you've said that's okay, and only for the reasons you’ve agreed to. For example, if you contact our Support team with a request, we will respond to you via email. We may occa-sionally send notification emails about new features, requests for feedback, important policy changes, or offer customer support.
            We use User Personal Information and other data to make recommendations for you, such as services you may want to use. These recommendations are automated decisions, but they have no legal impact on your rights.
            We collect Technical Information to better understand how our website visitors use our services, and to monitor and protect the security of the website.
            We collect personal information from third parties for the purposes for which it was authorized to be collected. For example, you may authorize us to contact you for marketing purposes via a third party's platform. If we need to use your personal information for other purposes, we will ask your permission first.
            We use your User Personal Information and Technical Infor-mation for internal purposes, such as to maintain logs for security reasons, for training purposes, and for legal docu-mentation and compliance.
            We limit our use of your User Personal Information to the purposes listed in this Privacy Statement. If we need to use your User Personal Information for other purposes, we will ask your permission first.
</p>
            <h2>Our legal basis for processing information</h2>
            <p>We process User Personal Information on the following legal bases:
            Contract Performance:
            When you create an account, you provide your user name and an email address. We require those data elements for you to enter into the user agreement with us, and we process those elements on the basis of performing that contract. We also process your user name and email address on other bases.
            If you use other services or other paid accounts with us, there will be other data elements we must collect and process on the basis of performing that contract.
            Consent:
            As a user, you can fill out the information in your user profile, and you have the option to provide User Personal Infor-mation such as your full name, which may include a photo-graph, your biography, your location, your company, and a URL to a third party website. If you decide to participate in a research project or survey, you may choose to provide User Personal Information or other personal information to us for limited purposes. We process this information on the basis of consent. All of this information is entirely optional, and you have the ability to access, modify, and delete it at any time (you are not able to delete your email address entirely).
            Legitimate Interests:
            Generally, the remainder of the processing of personal in-formation we perform is necessary for the purposes of our legitimate interests. For example, for legal compliance pur-poses or to maintain ongoing confidentiality, integrity, avail-ability and resilience of our website and service, we must keep logs of Technical Information.
            If you would like to request erasure of data we process on the basis of consent or object to our processing of personal in-formation, please contact us on info@buildingscout.eu.
</p>
            <h2>What information is not collected</h2>
            <p>We do not intentionally collect sensitive personal infor-mation, such as social security numbers, genetic data, health information, or religious information. Although we do not request or intentionally collect any other sensitive personal information, we realize that you might store this kind of infor-mation in your account, such as in your data or in your pro-file. If you store any sensitive personal information on our servers, you are responsible for complying with any regulato-ry controls regarding that data.
            If you're under the age of 18, you may not have an account. We do not knowingly collect information from or direct any of our content specifically to children. If we learn or have rea-son to suspect that you are a user who is under the age of 18, we will unfortunately have to close your account. Please see our user agreement for information about account termination. Other countries may have different minimum age limits, and if you are below the minimum age for providing consent for data collection in your country, you may not use our website and services.
            We do not intentionally collect User Personal Information that is stored in your account (Information such as for exam-ple uploaded data, media, data entries or other free-form con-tent inputs). Information in your account belongs to you, and you are responsible for it, as well as for making sure that your content complies with our user agreement. Any personal in-formation within a user's account is the responsibility of the account owner.
</p>
            <h2>Data contents</h2>
            <p>Our employees do not access private data content unless required to for security reasons, to assist the data owner with a support matter, or to maintain the integrity of the service. Our user agreements provide more details.</p>
            <h2>How we share the information we collect</h2>
            <p>We do share User Personal Information with your permis-sion, so we can perform services you have requested or communicate on your behalf.
            We do not share, sell, rent, or trade User Personal Infor-mation with third parties for their commercial purposes, ex-cept where you have specifically told us to.
            We do not host advertising on our accounts. We may occa-sionally embed content from third party sites, such as YouTube, and that content may include ads. While we try to minimize the amount of ads our embedded content contains, we can't always control what third parties show.
            We do not disclose User Personal Information outside our website and services, except in the situations listed in this section.
            We do share certain aggregated, non-personally identifying information with others about how our users, collectively, use our website and services, or how our users respond to our other offerings, such as our events or services. For example, we may compile statistics on the usage of licenses. However, we do not sell this information to advertisers or marketers.
            We do share User Personal Information with a limited number of third parties who process it on our behalf to provide or improve our service, and who have agreed to privacy re-strictions similar to our own Privacy Statement by signing data protection agreements. Our partners perform services such as payment processing, customer support ticketing, network data transmission, trainings and other similar ser-vices. When we transfer your data to our partners under EU-US and Swiss-US Privacy Shield Frameworks, we remain responsible for it. While we process all User Personal Infor-mation in Germany, third parties may process data in other EU member states.
            We do share aggregated, non-personally identifying infor-mation with third parties. For example, we share the number of data points, or in the event of a security incident, we may share the number of times a particular file was accessed.
            We may share User Personal Information if we are involved in a change of ownership, such as a merger, sale, or acquisi-tion. If any such change of ownership happens, we will en-sure that it is under terms that preserve the confidentiality of User Personal Information, and we will notify you on our website or by email before any transfer of your User Personal Information. The organization receiving any User Personal Information will have to honor any promises we have made in our Privacy Statement or in our user agreement.
</p>
            <h2>How you can access and control the information we collect</h2>
            <p>If you've already an account, you may access, update, alter, or delete your basic user profile information by editing your user profile or contacting info@buildingscout.eu . You can control the information we collect about you by limiting what infor-mation is in your profile, by updating out of date information, or by contacting info@buildingscout.eu.
            If we process information about you and you do not have an account, such as information we receive from third parties, then you may access, update, alter, delete, or object to the processing of your personal information by contacting in-fo@buildingscout.eu.
</p>
            <h2>Data retention and deletion of data</h2>
            <p>Generally, we will retain User Personal Information for as long as your account is active or as needed to provide you services.
            We may retain certain User Personal Information indefinitely, unless you delete it or request its deletion. For example, we don’t automatically delete inactive user accounts, so unless you choose to delete your account, we will retain your ac-count information indefinitely.
            If you would like to cancel your account or delete your User Personal Information, you may do so by contacting in-fo@buildingscout.eu. We will retain and use your information as necessary to comply with our legal obligations, resolve disputes, and enforce our agreements, but barring legal re-quirements, we will delete your full profile (within reason) within 90 days. You may contact info@buildingscout.eu to request the erasure of the data we process on the basis of consent within 30 days.
</p>
            <h2>Our use of cookies and tracking</h2>
            <p>Cookies
            We uses cookies to make interactions with our service easy and meaningful. We use cookies (and similar technologies, like HTML5 localStorage) to keep you logged in, remember your preferences, and provide information for future devel-opment. For security reasons, we use cookies to identify a device. By using our website, you agree that we can place these types of cookies on your computer or device. If you disable your browser or device’s ability to accept these cook-ies, you will not be able to log in or use to our services. On certain areas of the website, we may also use cookies to iden-tify you and/or your device to advertise our products and services to you on third party sites.
            Tracking and analytics
            We may use third party analytics and service providers to help us evaluate our users' use of our services; compile statis-tical reports on activity; and improve our content and website performance. We only use these third party analytics provid-ers on certain areas of our website, and all of them have signed data protection agreements with us that limit the type of personal information they can collect and the purpose for which they can process the information. In addition, we may use our own internal analytics software to provide features and improve our content and performance.
            Some browsers have incorporated "Do Not Track" (DNT) fea-tures that can send a signal to the websites you visit indicat-ing you do not wish to be tracked. We responds to browser DNT signals and follow the W3C standard for responding to DNT signals. If you have not enabled DNT on a browser that supports it, cookies on some parts of our website will track your online browsing activity on other online services over time, though we do not permit third parties other than our analytics and service providers to track our users' activity over time using our services.
</p>
            <h2>How your information is secured</h2>
            <p>We take all measures reasonably necessary to protect User Personal Information from unauthorized access, alteration, or destruction; maintain data accuracy; and help ensure the appropriate use of User Personal Information.
            In the event of a data breach that affects your User Personal Information, we will act promptly to mitigate the impact of a breach and notify any affected users without undue delay.
            Transmission of data on our websites is encrypted using SSH, HTTPS, and SSL/TLS, your data content is encrypted at rest. When data is stored with a third party storage provider, it is encrypted. We only use German third party storages providers.
            No method of transmission, or method of electronic storage, is 100% secure. Therefore, we cannot guarantee its absolute security.
            </p>
            <h2>Privacy practices</h2>
            <p>
                We store and process the information that we collect in the European Union in accordance with this Privacy Statement. However, we understand that we have users from different countries and regions with different privacy expectations, and we try to meet those needs even when the European Union does not have the same privacy framework as other countries'.
                We provide a high standard of privacy protection — as de-scribed in this Privacy Statement — to all our users, regard-less of their country of origin or location, and we are proud of the levels of notice, choice, accountability, security, data in-tegrity, access, and recourse we provide. We work hard to comply with the applicable data privacy laws wherever we do business. Additionally, if our partners or affiliates have ac-cess to User Personal Information, they must sign agree-ments that require them to comply with our privacy policies and with applicable data privacy laws.
            </p>
            <h2>How we respond to compelled disclosure</h2>
            <p>
                We may disclose personally-identifying information or other information we collect about you to law enforcement in re-sponse to a valid subpoena, court order, warrant, or similar government order, or when we believe in good faith that dis-closure is reasonably necessary to protect our property or rights, or those of third parties or the public at large.
                In complying with court orders and similar legal processes, we strive for transparency. When permitted, we will make a reasonable effort to notify users of any disclosure of their information, unless we are prohibited by law or court order from doing so, or in rare, exigent circumstances.
            </p>
            <h2>How we, and others, communicate with you</h2>
            <p>
                We will use your email address to communicate with you, if you've said that's okay, and only for the reasons you’ve said that’s okay. For example, if you contact our team with a re-quest, we will respond to you via email. Please note that you cannot opt out of receiving important communications from us, such as emails from our team or system emails.</p>
            <h2>Resolving complaints</h2>
            <p>
                If you have concerns about the way we are handling your User Personal Information, please let us know immediately. We want to help. You may contact info@buildingscout.eu with the subject line "Privacy Concerns." We will respond prompt-ly — within 10 days at the latest.</p>
            <h2>Dispute resolution process</h2>
            <p>In the unlikely event that a dispute arises between you and us regarding our handling of your User Personal Information, we will do our best to resolve it. If we cannot, we have select-ed to cooperate with the relevant EU Data Protection Authori-ty, or a panel established by the European data protection authorities, for resolving disputes with EU individuals for resolving disputes.</p>
            <h2>Changes to our Privacy Statement</h2>
            <p>Although most changes are likely to be minor, we may change our Privacy Statement from time to time. We will pro-vide notification to Users of material changes to this Privacy Statement through our Website by posting a notice on our home page or sending email to the primary email address specified in your account.</p>
        </ScrollContent>
    )
}

export default Privacy