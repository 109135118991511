import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { welcome as api } from '../../services/api'

const initialState = {
  value: 0,
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
// TODO

export const signupSlice = createSlice({
  name: 'signup',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    sayHello: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.value += 1;
    }
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  // TODO
});

export const { sayHello } = signupSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.signup.value)`
export const selectCount = (state) => state.signup.value;

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
// TODO
export const registerAccount = (data) => async dispatch => {
  try {
      const result = await api.register(data)

      const { status, id } = result

      if (status === 'ERROR') { return { success: false } }
      if (status === 'WARNING') { return { success: false } }

      return { success: true }
  } catch (error) {
      return { success: false, error }
  }
}

export default signupSlice.reducer;
