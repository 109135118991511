import { useEffect } from 'react'
import useGroupImages from '../hooks/useGroupImages'
import styles from './Pagination.module.css'

function Pagination(props) {

    const images = useGroupImages()

    useEffect(() => { })

    return (
        <div className={styles.container}>
            {props.data.length > 0
                && props.data.map(item => (
                    <div key={Math.random()} className={styles.item} onClick={() => props.onselect(item.id)}>
                        <img className={styles.image} src={images[item.group]} alt="group" />
                        <div className={styles.box}>
                            <span className={styles.text}>{item.name}</span>
                        </div>
                    </div>
                ))}
        </div>
    )
}

Pagination.defaultProps = {
    data: []
}

export default Pagination